import Chart from "chart.js/auto";
import "@/lib/chartjs-adapter-dayjs";
import "@/lib/chartjs-plugin-colorschemes";
import { usePortfolioStore } from "@/store";
import merge from "lodash/merge";
import { mapState } from "pinia";
import useDayjs from "./composables/useDayjs";
import useTranslate from "./composables/useTranslate";
import { formatCurrency } from "./lib/utils";

const defaultChartColors = [
	"#065440",
	"#2560E5",
	"#017D59",
	"#08DA85",
	"#AFFFB6",

	"#001219",
	"#005F73",
	"#94D2BD",
	"#E9D8A6",
	"#EE9B00",
	"#CA6702",
	"#BB3E03",
	"#AE2012",
	"#9B2226",
];
const getOrCreateLegendList = (chart, id) => {
	const legendContainer = document.getElementById(id);
	let listContainer = legendContainer?.querySelector("div");

	if (!listContainer) {
		listContainer = document.createElement("div");

		listContainer.style.display = "flex";
		listContainer.style.flexDirection = "row";
		listContainer.style.margin = "10";
		listContainer.style.padding = "0";
		listContainer.style.flexWrap = "wrap";

		legendContainer?.appendChild(listContainer);
	}

	return listContainer;
};

const htmlLegendPlugin = {
	id: "htmlLegend",

	afterDatasetUpdate(chart, args, options) {
		const div = getOrCreateLegendList(chart, options.containerID);

		//Remove old legend items
		while (div.firstChild) {
			div.firstChild.remove();
		}

		//Reuse the built-in legendItems generator
		const items = chart.options.plugins.legend.labels.generateLabels(chart);

		let ul;

		items.map((item, ix) => {
			const li = document.createElement("li");
			li.style.maxWidth = "50";
			li.style.alignItems = "center";
			li.style.cursor = "pointer";
			li.style.display = "flex";
			li.style.flexDirection = "row";
			li.style.marginLeft = "15px";
			li.style.marginBottom = "10px";

			li.onclick = () => {
				const { type } = chart.config;
				if (type === "pie" || type === "doughnut") {
					// Pie and doughnut charts only have a single dataset and visibility is per item
					chart.toggleDataVisibility(item.index);
				} else {
					chart.setDatasetVisibility(
						item.datasetIndex,
						!chart.isDatasetVisible(item.datasetIndex),
					);
				}
				chart.update();
			};

			// Color box
			const boxSpan = document.createElement("span");
			boxSpan.style.background = item.fillStyle;
			boxSpan.style.borderColor = item.strokeStyle;
			boxSpan.style.borderWidth = `${item.lineWidth}px`;
			boxSpan.style.display = "inline-block";
			boxSpan.style.flexShrink = "0";
			boxSpan.style.height = "20px";
			boxSpan.style.marginRight = "10px";
			boxSpan.style.width = "20px";

			// Text
			const textContainer = document.createElement("p");
			textContainer.style.color = item.fontColor;
			textContainer.style.margin = "0";
			textContainer.style.padding = "0";
			textContainer.style.overflow = "hidden";
			textContainer.style.textOverflow = "ellipsis";

			textContainer.style.textDecoration = item.hidden ? "line-through" : "";

			const text = document.createTextNode(item.text);
			textContainer.appendChild(text);

			li.appendChild(boxSpan);
			li.appendChild(textContainer);
			ul = document.createElement("ul");
			ul.style.width = item.text.length >= 7 ? "150px" : "100px";
			ul?.appendChild(li);
			div.appendChild(ul);
		});
	},
};

export default {
	computed: {
		...mapState(usePortfolioStore, ["ALL_MODELS", "RISK_PROFILE_OPTIONS"]),
		defaultChartColors() {
			return defaultChartColors;
		},
	},

	methods: {
		$t(...params) {
			const { t } = useTranslate();
			return t(...params).value;
		},

		roundNumber(number, precision = 0) {
			const factor = 10 ** precision;
			return Math.round(number * factor) / factor;
		},

		formatCurrency,

		formatDate(date) {
			const dayjs = useDayjs().value;
			return dayjs(date).format("ll");
		},

		formatDateTime(date) {
			const dayjs = useDayjs().value;
			return dayjs(date).format("llll");
		},

		fromNow(date) {
			const dayjs = useDayjs().value;
			return dayjs(date).fromNow();
		},

		modelName(key) {
			const model = this.ALL_MODELS.find((i) => i.key === key);
			if (model) {
				return this.$t(model.name);
			}
			return null;
		},

		getChartColor(index) {
			return this.defaultChartColors[index % this.defaultChartColors.length];
		},

		parseSymbol(symbol) {
			const reg = new RegExp(
				"" +
					"^(?<code>.+)" +
					"\\.(?<domain>[A-Z]{1,2})" +
					"\\.(?<exchange>[^.-]+)" +
					"(-(?<identifier>.+))?" +
					"$",
			);
			const match = symbol.match(reg);
			return match ? match.groups : undefined;
		},

		symbolCode(symbol) {
			return this.parseSymbol(symbol)?.code;
		},
	},

	directives: {
		/**
		 * example usage:
		 * {
		 *  options: (chartjs options object),
		 *  labels: ["label1", "label2", ...],
		 *  datasets: [
		 *   {
		 *    label: "Weights",
		 *    data: [23, 45, ...],
		 *   }
		 *  ]
		 * }
		 */
		renderDoughnutChart: {
			mounted(el, binding) {
				const {
					value: { labels, datasets, options = {} },
				} = binding;

				for (let i = 0; i < datasets.length; i++) {
					const { data } = datasets[i];
					if (data.length) {
						const total = data.reduce((a, b) => a + b, 0);
						datasets[i].data = data.map(
							(i) => Math.round((i * 10000) / total) / 100,
						);
					}
				}
				const id: string = datasets[0]?.label.replace(" ", "");
				const defaultOptions = {
					responsive: true,
					maintainAspectRatio: false,
					animation: true,
					cutout: "70%",
					plugins: {
						htmlLegend: {
							// ID of the container to put the legend in
							containerID: id,
						},
						legend: {
							display: false,
						},
						tooltip: {
							callbacks: {
								title: () => null,
								label: (item) => {
									const label =
										typeof item.label === "string"
											? item.label
											: item.label.symbol;
									return ` ${label}: %${item.formattedValue}`;
								},
								footer: (item) => {
									const name = labels[item[0].dataIndex]?.detail?.name;

									if (name) {
										let nameString = "";
										if (name.length <= 35) {
											nameString = name;
										} else {
											for (let i = 0; i < name.length; i += 35) {
												nameString += `${name.slice(i, i + 35)}${
													name.length <= i + 35 ? "" : "\n"
												}`;
											}
										}

										return nameString;
									}
								},
							},
						},

						colorschemes: {
							scheme: defaultChartColors,
						},
					},
				};
				el.doughnutChart = new Chart(el.getContext("2d"), {
					type: "doughnut",
					data: {
						labels: labels.map((l) => (typeof l === "string" ? l : l.symbol)),
						datasets,
					},
					options: merge(defaultOptions, options),
					plugins: [htmlLegendPlugin],
				});
			},
			updated(el, binding) {
				const {
					value: { labels, datasets, options = {} },
				} = binding;

				if (el.doughnutChart) {
					for (let i = 0; i < datasets.length; i++) {
						const { data } = datasets[i];
						const total = data.reduce((a, b) => a + b, 0);
						datasets[i].data = data.map(
							(i) => Math.round((i * 10000) / total) / 100,
						);
					}
					const id: string = datasets[0]?.label.replace(" ", "");

					const defaultOptions = {
						responsive: true,
						maintainAspectRatio: false,
						animation: true,
						cutout: "70%",
						plugins: {
							htmlLegend: {
								// ID of the container to put the legend in
								containerID: id,
							},
							legend: {
								display: false,
							},
							tooltip: {
								callbacks: {
									label: (item) => {
										const label =
											typeof item.label === "string"
												? item.label
												: item.label.symbol;
										return ` ${label}: %${item.formattedValue}`;
									},
									footer: (item) => {
										const name = labels[item[0].dataIndex]?.detail?.name;

										if (name) {
											let nameString = "";
											if (name.length <= 35) {
												nameString = name;
											} else {
												for (let i = 0; i < name.length; i += 35) {
													nameString += `${name.slice(i, i + 35)}${
														name.length <= i + 35 ? "" : "\n"
													}`;
												}
											}

											return nameString;
										}
									},
								},
							},

							colorschemes: {
								scheme: defaultChartColors,
							},
						},
					};
					el.doughnutChart.data.labels = labels.map((l) =>
						typeof l === "string" ? l : l.symbol,
					);
					el.doughnutChart.data.datasets = datasets;
					el.doughnutChart.options = merge(defaultOptions, options);
					el.doughnutChart.update();
				}
			},
		},
	},
};
