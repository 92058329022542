import useDayjs from "@/composables/useDayjs";
import axios from "@/lib/axios";
import { useAuthStore } from "@/store/auth";
import { Optimization } from "@dev-team/types/rest-api/models/Optimization";
import type {
	Metrics,
	Simulation,
	SimulationPortfolio,
} from "@dev-team/types/rest-api/models/Simulation";
import { Transaction } from "@dev-team/types/rest-api/models/Transaction";
import type { ModelName } from "@dev-team/types/rest-api/models/enums/ModelName";
import type { HistoryItem } from "@dev-team/types/rest-api/models/schemas/HistoryItem";
import { OptimizationConfig } from "@dev-team/types/rest-api/models/schemas/OptimizationConfig";
import { State } from "@dev-team/types/rest-api/models/schemas/State";
import { TransactionItem } from "@dev-team/types/rest-api/models/schemas/TransactionItem";
import { defineStore } from "pinia";
import { clone } from "../lib/utils";

interface ListColumns {
	title?: string;
	name: string;
	sortable?: boolean;
}
type MetricColumns = ListColumns;

interface ListParams {
	page: number;
	size: number;
	search: string | null;
	sort: string;
	asc: boolean | 0 | 1;
}

interface MetricListParams {
	sort: string;
	asc: boolean;
}

const DEFAULT_LIST_COLUMNS: ListColumns[] = [
	{
		name: "statusIcon",
	},
	{
		title: "NAME",
		name: "name",
		sortable: true,
	},
	{
		title: "CREATED AT",
		name: "createdAt",
		sortable: true,
	},
	{
		title: "START DATE",
		name: "investmentStartDate",
	},
	{
		title: "INVESTMENT PERIOD",
		name: "testPeriod",
		sortable: true,
	},
	{
		title: "INVESTMENT AMOUNT",
		name: "plannedInvestment",
		sortable: true,
	},
	{
		title: "BEST RETURN",
		name: "bestReturn",
		sortable: true,
	},
	{
		title: "BEST PERFORMANCE",
		name: "bestPerformance",
		sortable: true,
	},
	{
		title: "TOTAL PORTFOLIOS",
		name: "totalPortfolios",
		sortable: true,
	},
	{
		title: "ACTIONS",
		name: "actions",
		sortable: false,
	},
];

const DEFAULT_METRICS_COLUMNS: MetricColumns[] = [
	{
		title: "Name",
		name: "name",
		sortable: false,
	},
	{
		title: "Max Drawdown",
		name: "maxDrawdown",
		sortable: true,
	},
	{
		title: "Sortino",
		name: "sortino",
		sortable: true,
	},
	{
		title: "Realized Volatility",
		name: "realizedVolatility",
		sortable: true,
	},
	{
		title: "Sharpe",
		name: "sharpe",
		sortable: true,
	},
];

const DEFAULT_METRIC_LIST_PARAMS: MetricListParams = {
	sort: "name",
	asc: true,
};
const DEFAULT_LIST_PARAMS: ListParams = {
	page: 1,
	size: 6,
	search: null,
	sort: "createdAt",
	asc: true,
};

interface PortfolioMetrics {
	id: string;
	metrics: Metrics;
	name: string;
}

type SimulationRecord = Simulation & {
	totalProgress?: null | number;
	progress?: null | number;
};
interface StoreState {
	listLoading: boolean;
	listRecords: SimulationRecord[];
	listCount: number;
	listColumns: ListColumns[];
	listParams: ListParams;
	metricsColumns: MetricColumns[];
	metricListParams: MetricListParams;
	portfolioMetrics: PortfolioMetrics[];

	detailLoading: boolean;
	detailRecord: SimulationRecord | null;

	hasChanged: boolean | null;
}

interface SocketPortfolio {
	id: string;
	model: ModelName;
	metrics: Metrics;
	name: string;
	optimization: Optimization[];
	state: State;
	history: HistoryItem[];
	transactions: SocketTransaction[];
}

interface SocketPayload {
	id: string;
	configuration: OptimizationConfig;
	endDate: Date;
	startDate: Date;
	testPeriod: number;
	trainPeriod: number;
	investmentStartDate: Date;
	error: string | null;
	portfolios: SocketPortfolio[];
}

interface SocketTransaction {
	date: Date;
	optimization: string;
	items: TransactionItem[];
	before: State;
	after: State;
}

export const useSimulationStore = defineStore("simulation", {
	state: (): StoreState => ({
		listLoading: false,
		listRecords: [],
		listCount: 0,
		listColumns: clone(DEFAULT_LIST_COLUMNS),
		listParams: clone(DEFAULT_LIST_PARAMS),
		metricsColumns: clone(DEFAULT_METRICS_COLUMNS),
		metricListParams: clone(DEFAULT_METRIC_LIST_PARAMS),
		portfolioMetrics: [],

		detailLoading: false,
		detailRecord: null,

		hasChanged: null,
	}),

	getters: {
		permission(state) {
			const { userPlan } = useAuthStore();
			const dayjs = useDayjs().value;
			const permission: { canCreate: boolean; reason: string | null } = {
				canCreate: true,
				reason: null,
			};
			if (!userPlan) {
				return permission;
			}
			// simulations
			if (userPlan.details.simulations !== "enabled") {
				permission.canCreate = false;
				permission.reason = "You don't have permission to create simulation";
			}
			// maxSimulationCount
			if (state.listCount >= userPlan.details.maxSimulationCount) {
				permission.canCreate = false;
				permission.reason =
					"You have reached the maximum number of simulations";
			}
			// dailySimulationLimit
			const dailySimulationCount = state.listRecords.filter((record) => {
				if (dayjs(record.createdAt).isSame(dayjs(), "day")) {
					return true;
				}
			}).length;
			if (dailySimulationCount >= userPlan.details.dailySimulationLimit) {
				permission.canCreate = false;
				permission.reason = "You have reached the daily limit of simulations";
			}
			return permission;
		},
	},

	actions: {
		async fetchList() {
			this.listLoading = true;
			try {
				const params = Object.assign({}, DEFAULT_LIST_PARAMS, this.listParams);
				params.asc = Number(params.asc) as 0 | 1;
				const {
					data: { records, count },
				}: {
					data: {
						records: SimulationRecord[];
						count: number;
					};
				} = await axios.get("/simulation/list", { params });
				this.listRecords = records.map((i) => {
					i.progress = null;
					i.totalProgress = null;
					return i;
				});
				this.listCount = count;

				await Promise.all(
					this.listRecords
						.filter((item) => item.status === "started")
						.map((item) => this.sendLoadSignal(item.id)),
				);

				this.listLoading = false;
			} catch (err) {
				this.listLoading = false;
				throw err;
			}
		},

		async fetchDetail({
			id,
			withTransactions,
		}: {
			id: string;
			withTransactions: boolean;
		}) {
			this.detailLoading = true;
			try {
				const { data }: { data: SimulationRecord } = await axios.get(
					`/simulation/${id}`,
					{
						params: { withTransactions },
					},
				);

				if (data.status === "started") {
					this.sendLoadSignal(data.id);
				}

				this.$patch((state) => {
					state.detailRecord = {
						...data,
						progress: null,
						totalProgress: null,
					};
					this.portfolioMetrics = data.portfolios.map((p) => {
						return {
							id: p.id,
							name: p.portfolio.name,
							metrics: p.metrics,
						} as PortfolioMetrics;
					});
				});
				this.detailLoading = false;
				return data;
			} catch (err) {
				this.detailLoading = false;
				throw err;
			}
		},

		async deleteRecord(id: string) {
			await axios.delete(`/simulation/${id}`);
		},

		async sendLoadSignal(id: string) {
			await axios.get(`/simulation/load-signal/${id}`);
		},

		async sendCancelSignal(id: string) {
			// update list items:
			for (let i = 0; i < this.listRecords.length; i++) {
				if (this.listRecords[i].id === id) {
					this.$patch((state) => (state.listRecords[i].status = "canceling"));
					break;
				}
			}
			// update detail item:
			if (this.detailRecord && this.detailRecord.id === id) {
				this.$patch({ detailRecord: { status: "canceling" } });
			}
			await axios.get(`/simulation/cancel-signal/${id}`);
		},

		async SOCKET_simulation_started({
			simulationId,
		}: {
			simulationId: string;
		}) {
			// update list items:
			for (let i = 0; i < this.listRecords.length; i++) {
				if (this.listRecords[i]?.id === simulationId) {
					this.$patch((state) => (state.listRecords[i].status = "started"));
					break;
				}
			}
			// update detail item:
			if (this.detailRecord?.id === simulationId) {
				this.sendLoadSignal(simulationId);
				this.$patch({ detailRecord: { status: "started" } });
			}
		},

		SOCKET_simulation_finished({
			simulationId,
			...payload
		}: { simulationId: string } & SocketPayload) {
			// update list items:
			for (let i = 0; i < this.listRecords.length; i++) {
				if (this.listRecords[i]?.id === simulationId) {
					this.$patch((state) => (state.listRecords[i].status = "finished"));
					break;
				}
			}
			// update detail item:
			if (this.detailRecord?.id === simulationId) {
				this.$patch((state) => {
					if (!state.detailRecord) {
						return;
					}
					state.detailRecord?.portfolios.forEach((p) => {
						const sPort = payload.portfolios.find(
							(simPort) => simPort.id === p.id,
						);

						if (sPort) {
							p.metrics = sPort.metrics;
						}
					});
					state.detailRecord.status = "finished";

					//update sim-portfolios if simulation_loaded event do not trigger
					if (!state.detailRecord.portfolios.length) {
						payload.portfolios.forEach((p) => {
							{
								const port = {
									_id: p.id,
									model: p.model,
									metrics: p.metrics,
									portfolio: {
										id: p.id,
										name: p.name,
										history: p.history,
										transactions: p.transactions.map(mapTransactionObject),
									},
								} as unknown as SimulationPortfolio;
								state.detailRecord?.portfolios.push(port);
							}
						});
					}
				});
			}

			//update metrics
			this.portfolioMetrics = payload.portfolios.map((p) => {
				return {
					id: p.id,
					name: p.name,
					metrics: p.metrics,
				};
			});
		},

		SOCKET_simulation_canceled({ simulationId }: { simulationId: string }) {
			// update list item:
			for (let i = 0; i < this.listRecords.length; i++) {
				if (this.listRecords[i].id === simulationId) {
					this.$patch((state) => (state.listRecords[i].status = "canceled"));
					break;
				}
			}
			// update detail item:
			if (this.detailRecord?.id === simulationId) {
				this.$patch({ detailRecord: { status: "canceled" } });
			}
		},

		SOCKET_simulation_loaded({
			simulationId,
			...data
		}: { simulationId: string } & SocketPayload) {
			const sim = {
				...data,
				portfolios: data.portfolios.map((item) => {
					item.transactions = item.transactions.map(
						mapTransactionObject,
					) as unknown as SocketTransaction[];
					return {
						...item,
						portfolio: item,
					};
				}),
				progress: null,
				totalProgress: null,
			};
			// update list item:
			for (let i = 0; i < this.listRecords.length; i++) {
				if (this.listRecords[i].id === simulationId) {
					this.$patch((state) => {
						Object.assign(state.listRecords[i], sim);
						state.hasChanged = true;
					});
					break;
				}
			}
			// update detail item:
			if (this.detailRecord && this.detailRecord.id === simulationId) {
				this.$patch({
					detailRecord: sim as unknown as SimulationRecord /*{
            ...data,
            portfolios: data.portfolios.map((item) => {
              item.transactions = item.transactions.map(mapTransactionObject);
              return {
                ...item,
                portfolio: item,
              };
            }),
            progress: null,
            totalProgress: null,
          },*/,
				});
			}
		},

		SOCKET_simulation_progress({
			progress,
			total,
			simulationId,
		}: {
			progress: number;
			total: number;
			simulationId: string;
		}) {
			// update list items:
			for (let i = 0; i < this.listRecords.length; i++) {
				if (this.listRecords[i].id === simulationId) {
					this.$patch((state) => {
						state.listRecords[i].progress = progress;
						state.listRecords[i].totalProgress = total;
					});

					break;
				}
			}

			// update detail item:
			if (this.detailRecord && this.detailRecord.id === simulationId) {
				this.$patch((state) => {
					if (!state.detailRecord) {
						return;
					}
					state.detailRecord.progress = progress;
					state.detailRecord.totalProgress = total;
				});
			}
		},

		SOCKET_simulation_updateState({
			simulationId,
			portfolioId,
			state,
		}: {
			simulationId: string;
			portfolioId: string;
			state: State;
		}) {
			if (this.detailRecord && this.detailRecord.id === simulationId) {
				this.$patch((stateObj) => {
					if (!stateObj.detailRecord) {
						return;
					}
					stateObj.detailRecord.portfolios.forEach((simPortfolio) => {
						if (simPortfolio.portfolio.id === portfolioId) {
							simPortfolio.portfolio.state = state;
						}
					});
				});
			}
		},

		SOCKET_simulation_transaction({
			simulationId,
			portfolioId,
			transaction,
		}: {
			simulationId: string;
			portfolioId: string;
			transaction: SocketTransaction;
		}) {
			if (!transaction) return;

			if (this.detailRecord && this.detailRecord.id === simulationId) {
				this.$patch((state) => {
					if (!state.detailRecord) {
						return;
					}

					for (const simPortfolio of state.detailRecord.portfolios) {
						if (simPortfolio.portfolio.id === portfolioId) {
							simPortfolio.portfolio.transactions.push(
								mapTransactionObject(transaction) as unknown as Transaction,
							);
						}
					}
				});
			}
		},

		SOCKET_simulation_historyEntry({
			simulationId,
			portfolioId,
			historyEntry,
		}: {
			simulationId: string;
			portfolioId: string;
			historyEntry: HistoryItem;
		}) {
			if (this.detailRecord && this.detailRecord.id === simulationId) {
				this.$patch((state) => {
					if (!state.detailRecord) {
						return;
					}

					for (const simPortfolio of state.detailRecord.portfolios) {
						if (simPortfolio.portfolio.id === portfolioId) {
							simPortfolio.portfolio.history.push(historyEntry);
						}
					}
				});
			}
		},
	},
});

function mapTransactionObject(transaction: SocketTransaction) {
	return {
		...transaction,
		createdAt: transaction?.date,
	};
}
